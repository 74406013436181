import Cookies from "../../scripts/js-cookie/dist/js.cookie.mjs";

const submitBtn = document.querySelector('.login__submit')
const loginBtn = document.querySelector('.login__btn')
const loginModal = document.querySelector('.login__modal')
const closeModalBtn = document.querySelector('.login__close-modal')
const showPassBtn = document.querySelector('#show-password-btn')
const emailInput = document.querySelector('#user-email')
const passwordInput = document.querySelector('#user-password')
const body = document.body


loginBtn.addEventListener('click', () => {
	loginModal.classList.add('active');
	loginBtn.setAttribute('disabled', true)
});

closeModalBtn.addEventListener('click', () => {
	loginModal.classList.remove('active')
	loginBtn.removeAttribute('disabled')
})


/* To show password
---------------------------------------------------------------*/
showPassBtn.addEventListener('click', () => {
	showPassBtn.classList.toggle('show')
	if (showPassBtn.classList.contains('show')) {
		passwordInput.type = 'text'
	} else {
		passwordInput.type = 'password'
	}
})


/* To submit form
---------------------------------------------------------------*/
$("#login-form").submit(function (e) {
	e.preventDefault();
	var $form = $(this);
	submitBtn.setAttribute('disabled', '');


	$.ajax({
		type: "GET",
		url: $('#login-form').attr('action'),
		data: $form.serialize(),
		success: function (data) {
			if (data.user != 'exists') {
				console.log('!exists');
				submitBtn.removeAttribute('disabled');

				// у input c классом error фоновый цвет изменяется на крастный
				// как в макете (добавишь в нужный - emailInput.classList.add('error') )
				openNoticeModal('Wrong login or password!');
			}
			else {
				if (data.lp.remember == 'on') {
					Cookies.set('userLogin', data.lp.login);
					Cookies.set('userPassword', data.lp.password);
				}
				window.location.replace(data.urlToRedirect);
			}
		},
		error: function (data) {
			submitBtn.removeAttribute('disabled');
		}
	});
});


/* Modal functions
---------------------------------------------------------------*/
function openNoticeModal(text) {
	let notice = document.querySelector('#modal-notice')
	let closeBtn = notice.querySelector('.modal__close')
	let title = notice.querySelector('.modal__title')
	let body = document.body

	title.textContent = `${text}`
	body.classList.add('active', 'notice')

	notice.classList.add('active')
	closeBtn.addEventListener('click', closeNoticeModal)

	function closeNoticeModal() {
		notice.classList.remove('active')
		body.classList.remove('active', 'notice')
		setTimeout(() => { title.textContent = '' }, 350)
		closeBtn.removeEventListener('click', closeNoticeModal)
	}
}


/**
 *                                                      Прелоадер
 * --------------------------------------------------------------------------------------------------------------------------------------------
 */
document.addEventListener('DOMContentLoaded', () => {
	document.body.classList.add('loaded');
})